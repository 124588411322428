import * as React from 'react';
import { sortBy as _sortBy, map as _map } from 'lodash';
import Box from '@lce/slice_v2/Layout/Box';

import { Direction } from '@lce/intl-types/src/IDirection';

import { IOrderingModalProps } from '../../../../api/OrderingModal/useOrderingModal';
import { StoreLocation } from '../../../Patterns/StoreLocation';
import { ILocationListDefaultProps, IStoreLocationProps } from '../../../../api/ContactPage/useLocation';

export interface IStoreLocationsProps {
  locations: IStoreLocationProps[];
  locationDefault: ILocationListDefaultProps;
  modalContent: IOrderingModalProps;
  showSearchBar: boolean;
  direction: Direction;
}

const StoreLocationsList: React.FC<IStoreLocationsProps> = ({ locations, locationDefault, modalContent, showSearchBar, direction }) => (
  <Box
    data-testid="LocationList"
    sx={{
      p: ['13px 0 1.5em', '13px 0 1.5em', '0 20px 1.5em'],
      width: '100%',
      height: 'auto',
      gridArea: 'list',
      gridRowStart: showSearchBar ? 'list' : ['list', 'list', 'label'],
      display: 'flex',
      flexDirection: 'column',
      gap: ['13px', '13px', '22px'],
    }}
  >
    {_map(locationDefault.isSorted ? _sortBy(locations, 'name') : locations, (location, index) => (
      <StoreLocation data-testid={location.name.replace(/\s+/g, '-')} key={index} {...location} {...locationDefault} direction={direction} modalContent={modalContent} />
    ))}
  </Box>
);

export default StoreLocationsList;
