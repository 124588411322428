/* eslint-disable max-lines-per-function */
import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import { Direction } from '@lce/intl-types/src/IDirection';
import MapIdProvider from '@lce/intl-util/src/api/SelectedMapId/context/MapIdProvider';
import { IMapDisplayProps } from '@lce/intl-ui/src/PageComponents/ContactUs/Map/components/MarkerLoader';

import { IOrderingModalProps } from '../../../api/OrderingModal/useOrderingModal';
import { ILocationListDefaultProps, IStoreLocationProps } from '../../../api/ContactPage/useLocation';
import Map from './Map';
import LocationsSearchBar from './LocationsSearchBar';
import StoreLocationsList from './StoreLocationsList';

export interface IContactUs {
  locationDefault: ILocationListDefaultProps;
  mapDisplay: IMapDisplayProps;
  locationsList: IStoreLocationProps[];
  googleMapKey: string;
  direction: Direction;
  legalText: string;
  modalContent: IOrderingModalProps;
}

const ContactUs: React.FC<IContactUs> = ({
  locationDefault,
  mapDisplay,
  locationsList,
  googleMapKey,
  legalText,
  modalContent,
  direction,
}) => {
  const [filteredLocations, setFilteredLocations] = React.useState(Object.values(locationsList));
  const [filteredMapLocations, setFilteredMapLocations] = React.useState(Object.values(mapDisplay.locations));

  React.useEffect(() => {
    setFilteredLocations(Object.values(locationsList));
    setFilteredMapLocations(Object.values(mapDisplay.locations));
  }, [locationsList, mapDisplay.locations]);

  const showSearchBar = Object.keys(locationsList).length > 1 && locationDefault.isSearchEnabled;

  return (
    <MapIdProvider>
      <Box
        data-testId="contact-us-page"
        sx={{
          display: 'grid',
          gridTemplateAreas: [
            `'label' 'search' 'map' 'list' 'legal'`,
            `'label' 'search' 'map' 'list' 'legal'`,
            `'label none' 'search map' 'list map' 'legal legal'`,
          ],
          gridTemplateColumns: ['1fr', '1fr', '1fr 1fr'],
          gridTemplateRows: showSearchBar ? 'auto 64px repeat(3, auto)' : 'repeat(3, auto)',
          maxWidth: '1280px',
          mx: 'auto',
          p: [4, '32px 16px'],
          width: '100%',
        }}
      >
        {showSearchBar && (
          <LocationsSearchBar
            direction={direction}
            locations={Object.values(locationsList)}
            mapLocations={Object.values(mapDisplay.locations)}
            searchLabel={locationDefault.searchLabel}
            setFilteredLocations={setFilteredLocations}
            setFilteredMapLocations={setFilteredMapLocations}
          />
        )}
        <StoreLocationsList
          direction={direction}
          locationDefault={locationDefault}
          locations={filteredLocations}
          modalContent={modalContent}
          showSearchBar={showSearchBar}
        />
        <Map
          apiKey={googleMapKey}
          center={mapDisplay.center}
          locations={filteredMapLocations}
          markerImage={mapDisplay.markerImage}
          showSearchBar={showSearchBar}
          zoom={mapDisplay.zoom}
        />
        {legalText && (
          <Box
            dangerouslySetInnerHTML={{ __html: legalText }}
            sx={{
              fontFamily: 'tertiary',
              fontSize: '16px',
              gridArea: 'legal',
            }}
          />
        )}
      </Box>
    </MapIdProvider>
  );
};

export default ContactUs;
