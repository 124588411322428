import { SxStyleProp } from 'theme-ui';

export const Card: SxStyleProp = {
  borderTop: 'solid 4px',
  borderColor: 'primary',
  boxShadow: '0 0 10px 0 rgba(86, 86, 86, 0.32)',
  display: 'flex',
  flexDirection: 'column',
  fontSize: '20px',
  gap: ['10px', '18px'],
  lineHeight: '1.2em',
  p: ['16px 20px', '32px 36px'],
};
