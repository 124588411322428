import { SxStyleProp } from 'theme-ui';

export const FormLabel: SxStyleProp = {
  px: [0, 0, '20px'],
  width: '100%',
  height: 'auto',
  gridArea: 'label',
  fontFamily: 'tertiary',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: '1.5',
};

export const SearchBoxWrapper: SxStyleProp = {
  px: ['0', '0', '20px'],
  pb: ['13px', '13px', '22px'],
  width: '100%',
  height: 'auto',
  gridArea: 'search',
  position: 'relative',
};

export const SearchBoxForm: SxStyleProp = {
  borderRadius: 0,
  borderColor: '#767679',
  pr: '39px',
  ':focus':{
    borderRadius: 0,
    borderColor: 'black',
    outline:'none',
    caretColor: '#ff6000',
  },
};

export const SearchBoxButtonLeft: SxStyleProp = {
  position: 'absolute', 
  right: [ '11px', '11px', '31px' ], 
  top: '11px',
  cursor: 'pointer',
};

export const SearchBoxButtonRight: SxStyleProp = {
  position: 'absolute', 
  left: [ '11px', '11px', '31px' ], 
  top: '11px',
  cursor: 'pointer',
};
