import * as React from 'react';
import { IDefaultPageProps } from '@lce/intl-types/src/IDefaultPageProps';
import useSiteConfig from '../api/Config/useSiteConfig';
import Layout from '../ui/ConnectedLayout';
import ContactUs from '../ui/PageComponents/ContactUs';
import useLocation from '../api/ContactPage/useLocation';
import useOrderingModal from '../api/OrderingModal';

const ContactUsPage: React.FC<IDefaultPageProps> = props => {
  const { locale } = props.pathContext;
  const locationData = useLocation(locale);
  const modalData = useOrderingModal(locale);

  const { siteConfig: { googleMapKey }, direction } = useSiteConfig(locale);

  return (
    <Layout {...props} showStartYourOrder={false}>
      <ContactUs {...locationData} direction={direction} googleMapKey={googleMapKey} legalText={locationData.legalContent.legalText}  modalContent={modalData} />
    </Layout>
  );
};

export default ContactUsPage;
