import * as React from 'react';
import Box from '@lce/slice_v2/Layout/Box';
import { useUpdateMapId } from '@lce/intl-util/src/api/SelectedMapId/hooks/useUpdateMapId';
import { Direction } from '@lce/intl-types/src/IDirection';

import { IOrderingModalProps } from '../../../api/OrderingModal/useOrderingModal';
import { IDeliveryOptionsProps, IDirectionsProps } from '../../../api/ContactPage/useLocation';
import { LocationAddress, LocationHours, LocationPhone, LocationButtons } from './components';
import * as Styled from './StoreLocation.style';

export interface IStoreLocationProps {
  address: string | string[];
  defaultHours: string | string[];
  deliveryButtonLabel: string;
  deliveryOptions: IDeliveryOptionsProps[];
  detailPageUrl?: string;
  direction: Direction;
  directions: IDirectionsProps;
  directionsButtonLabel: string;
  hours?: string | string[];
  hoursLabel: string;
  latitude: number;
  longitude: number;
  modalContent: IOrderingModalProps;
  name: string;
  noPickupOrDeliveryText: string;
  pickupButtonLabel: string;
  pickupLink: string;
  telephoneNumber?: string;
}

export const StoreLocation: React.FC<IStoreLocationProps> = ({
  address,
  defaultHours,
  deliveryButtonLabel,
  deliveryOptions,
  detailPageUrl,
  direction: pageDirection,
  directions,
  directionsButtonLabel,
  hours,
  hoursLabel,
  latitude,
  longitude,
  modalContent,
  name,
  noPickupOrDeliveryText, 
  pickupButtonLabel,
  pickupLink,
  telephoneNumber,
}) => {
  const [setMapId, unsetMapId] = useUpdateMapId();
  const key = `${latitude}${longitude}`;

  return (
    <Box
      data-testid="storeLocation"
      onFocus={() => setMapId(key)}
      onMouseEnter={() => setMapId(key)}
      onMouseLeave={() => unsetMapId()}
      sx={Styled.Card}
    >
      <LocationAddress
        address={address}
        directions={directions}    
        directionsButtonLabel={directionsButtonLabel}    
        modalContent={modalContent}
        name={name}
        detailPageUrl={detailPageUrl}
      />
      <LocationHours defaultHours={defaultHours} hours={hours} hoursLabel={hoursLabel} />
      <LocationPhone direction={pageDirection} telephoneNumber={telephoneNumber} />
      <LocationButtons
        deliveryButtonLabel={deliveryButtonLabel}
        deliveryOptions={deliveryOptions}
        modalContent={modalContent}
        noPickupOrDeliveryText={noPickupOrDeliveryText}
        pickupButtonLabel={pickupButtonLabel}
        pickupLink={pickupLink}
      />
    </Box>
  );
};
